function groupBy(items, key, formatFn = item => item) {
    return items.reduce((accumulator, current_item) => {
        (accumulator[formatFn(current_item[key])] = accumulator[formatFn(current_item[key])] || []).push(current_item);
        return accumulator;
    }, {});
}

/**
 * Creates a memoized version of a function.
 * The function's results are cached based on the arguments provided.
 *
 * @param {Function} fn - The function to memoize
 * @returns {Function} - A memoized version of the input function
 */
function memoize(fn) {
    const cache = new Map();

    return function (...args) {
        const key = JSON.stringify(args);
        if (!cache.has(key)) {
            // eslint-disable-next-line no-invalid-this
            cache.set(key, fn.apply(this, args));
        }
        return cache.get(key);
    };
}
const EMPTY_VALUE = '__EMPTY_VALUE__';

export {groupBy, memoize, EMPTY_VALUE};
