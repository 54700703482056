import {
    faAdd,
    faAddressCard,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faArrowDown,
    faArrowDownFromLine as farArrowDownFromLine,
    faArrowDownRight,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowRightArrowLeft as farArrowRightArrowLeft,
    faArrowRightFromArc as farArrowRightFromArc,
    faArrowRightFromArc,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faArrowRightToArc as farArrowRightToArc,
    faArrowRotateLeft,
    faArrowsRotate,
    faArrowUp,
    faArrowUpFromLine as farArrowUpFromLine,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faAt,
    faBank,
    faBars,
    faBattery,
    faBell as farBell,
    faBook as farBook,
    faBookOpen as farBookOpen,
    faBoxArchive,
    faBriefcase as farBriefcase,
    faBriefcaseMedical as farBriefcaseMedical,
    faBuilding,
    faBuildings,
    faBusinessTime,
    faCalculator as farCalculator,
    faCalculatorSimple as farCalculatorSimple,
    faCalendar as farCalendar,
    faCalendarClock as farCalendarClock,
    faCalendarDay as farCalendarDay,
    faCalendars as farCalendars,
    faCalendarWeek as farCalendarWeek,
    faCalendarXmark,
    faCar,
    faChartLine as farChartLine,
    faCheck,
    faCheckDouble,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle as farCircle,
    faCircleCheck as farCircleCheck,
    faCircleDashed as farCircleDashed,
    faCircleEllipsis,
    faCircleExclamation as farCircleExclamation,
    faCircleInfo as farCircleInfo,
    faCircleQuestion,
    faCircleXmark as farCircleXmark,
    faClipboardList as farClipboardList,
    faClipboardListCheck as farClipboardListCheck,
    faClock,
    faClockThree,
    faClose,
    faCoin,
    faCoins as farCoins,
    faComment,
    faCommentDots,
    faCopy,
    faDesktop,
    faDollar,
    faDownload,
    faDumbbell,
    faEdit,
    faEllipsis,
    faEnvelope,
    faEnvelopesBulk,
    faEquals as farEquals,
    faEuroSign,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileCirclePlus,
    faFileContract,
    faFileCsv,
    faFileDoc,
    faFileImport,
    faFileInvoice as farFileInvoice,
    faFileInvoiceDollar as farFileInvoiceDollar,
    faFileLines as farFileLines,
    faFileMagnifyingGlass as farFileMagnifyingGlass,
    faFilePdf,
    faFilePlus,
    faFiles as farFiles,
    faFiles,
    faFileSignature,
    faFileSpreadsheet,
    faFileXmark,
    faFileZip as farFileZip,
    faFilter,
    faFloppyDisk as farFloppyDisk,
    faFolder as farFolder,
    faFolderOpen,
    faFolders as farFolders,
    faForward,
    faForwardFast,
    faGavel,
    faGear as farGear,
    faGlobe as farGlobe,
    faGripDotsVertical,
    faHand,
    faHandHoldingDollar as farHandHoldingDollar,
    faHandWave as farHandWave,
    faHelmetSafety,
    faHotel,
    faHourglassHalf as farHourglassHalf,
    faHouse as farHouse,
    faHouseBlank,
    faIdCard as farIdCard,
    faImage,
    faInputNumeric as farInputNumeric,
    faInputText as farInputText,
    faIslandTropical,
    faKey as farKey,
    faKey,
    faLaptopMobile as farLaptopMobile,
    faLightbulb,
    faLink,
    faList as farList,
    faListCheck as farListCheck,
    faListDropdown as farListDropdown,
    faLoader as farLoader,
    faLocationDot,
    faLocationDotSlash,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMegaphone,
    faMessage as farMessage,
    faMinusCircle as farMinusCircle,
    faMobile,
    faMoneyBill1Wave,
    faNote,
    faPaperclip,
    faPartyHorn,
    faPause,
    faPen,
    faPenField as farPenField,
    faPercent,
    faPercentage as farPercentage,
    faPhone,
    faPizzaSlice as farPizzaSlice,
    faPlane as farPlane,
    faPlay,
    faPlugCircleMinus,
    faPlugCirclePlus,
    faPlus,
    faPlusCircle as farPlusCircle,
    faPowerOff,
    faPuzzlePiece,
    faQuestion,
    faRepeat,
    faRobot as farRobot,
    faRocket as farRocket,
    faRotate,
    faRotateLeft,
    faSackDollar,
    faScaleBalanced,
    faSearch,
    faSend,
    faShieldCheck,
    faShieldQuartered,
    faSidebar as farSidebar,
    faSidebarFlip as farSidebarFlip,
    faSignature,
    faSliders as farSliders,
    faSliders,
    faSmileWink as farSmileWink,
    faSms,
    faStar as farStar,
    faStethoscope,
    faSuitcase,
    faTablet,
    faThumbtack,
    faToggleOn as farToggleOn,
    faTrash,
    faTriangleExclamation as farTriangleExclamation,
    faTriangleExclamation,
    faUndo as farUndo,
    faUpload,
    faUser as farUser,
    faUserCheck as farUserCheck,
    faUserClock as farUserClock,
    faUserCrown,
    faUserGraduate as farUserGraduate,
    faUserGroup as farUserGroup,
    faUserHelmetSafety as farUserHelmetSafety,
    faUserMagnifyingGlass as farUserMagnifyingGlass,
    faUserPen,
    faUserPlus as farUserPlus,
    faUsers as farUsers,
    faUsers,
    faUserSlash as farUserSlash,
    faUserTie as farUserTie,
    faUserVneckHair as farUserVneckHair,
    faUserXmark as farUserXmark,
    faUserXmark,
    faUtensils,
    faVideo,
    faWandMagicSparkles,
    faWarning as farWarning,
    faXmark,
    faXmarkToSlot,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faArchive as fasArchive,
    faArrowDownFromLine as fasArrowDownFromLine,
    faArrowRightArrowLeft as fasArrowRightArrowLeft,
    faArrowRightFromBracket as fasArrowRightFromBracket,
    faArrowUpFromLine as fasArrowUpFromLine,
    faBars as fasBars,
    faBell as fasBell,
    faBook as fasBook,
    faBookOpen as fasBookOpen,
    faBriefcase as fasBriefcase,
    faBuilding as fasBuilding,
    faBuildings as fasBuildings,
    faCalculator as fasCalculator,
    faCalendar as fasCalendar,
    faCalendars as fasCalendars,
    faCalendarWeek as fasCalendarWeek,
    faCaretDown,
    faCaretUp,
    faChartLine as fasChartLine,
    faCircleCheck as fasCircleCheck,
    faCircleDashed as fasCircleDashed,
    faCircleExclamation as fasCircleExclamation,
    faCircleInfo as fasCircleInfo,
    faCircleXmark as fasCircleXmark,
    faClock as fasClock,
    faCoins as fasCoins,
    faComment as fasComment,
    faFileCheck as fasFileCheck,
    faFileCirclePlus as fasFileCirclePlus,
    faFileLines as fasFileLines,
    faFiles as fasFiles,
    faFileSignature as fasFileSignature,
    faFolder as fasFolder,
    faGear as fasGear,
    faHouse as fasHouse,
    faInputNumeric as fasInputNumeric,
    faInputText as fasInputText,
    faKey as fasKey,
    faLaptopMobile as fasLaptopMobile,
    faList as fasList,
    faListCheck as fasListCheck,
    faListDropdown as fasListDropdown,
    faLoader as fasLoader,
    faMars,
    faMessage as fasMessage,
    faPen as fasPen,
    faPenField as fasPenField,
    faPizzaSlice as fasPizzaSlice,
    faPlugCircleMinus as fasPlugCircleMinus,
    faPlugCirclePlus as fasPlugCirclePlus,
    faPuzzlePiece as fasPuzzlePiece,
    faRocket as fasRocket,
    faScaleBalanced as fasScaleBalanced,
    faShieldCheck as fasShieldCheck,
    faShieldQuartered as fasShieldQuartered,
    faSliders as fasSliders,
    faSquare1,
    faSquare2,
    faThumbtack as fasThumbtack,
    faToggleOn as fasToggleOn,
    faTriangleExclamation as fasTriangleExclamation,
    faUser as fasUser,
    faUserGroup as fasUserGroup,
    faUserHelmetSafety as fasUserHelmetSafety,
    faUserPlus as fasUserPlus,
    faUsers as fasUsers,
    faVenus,
    faVideo as fasVideo,
} from '@fortawesome/pro-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(
    faAdd,
    faAddressCard,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faArrowDown,
    faArrowDownRight,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowRightFromArc,
    faArrowRotateLeft,
    faArrowsRotate,
    faArrowUp,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faAt,
    faBank,
    faBars,
    faBattery,
    faBoxArchive,
    faBuilding,
    faBuildings,
    faBusinessTime,
    faCalendarXmark,
    faCar,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckDouble,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleEllipsis,
    faCircleQuestion,
    faClock,
    faClockThree,
    faClose,
    faCoin,
    faComment,
    faCommentDots,
    faCopy,
    faDesktop,
    faDollar,
    faDownload,
    faDumbbell,
    faEdit,
    faEllipsis,
    faEnvelope,
    faEnvelopesBulk,
    faEuroSign,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileCirclePlus,
    faFileContract,
    faFileCsv,
    faFileDoc,
    faFileImport,
    faFilePdf,
    faFilePlus,
    faFiles,
    faFileSignature,
    faFileSpreadsheet,
    faFileXmark,
    faFilter,
    faFolderOpen,
    faForward,
    faForwardFast,
    faGavel,
    faGripDotsVertical,
    faHand,
    faHelmetSafety,
    faHotel,
    faHouseBlank,
    faImage,
    faIslandTropical,
    faKey,
    faLightbulb,
    faLink,
    faLocationDot,
    faLocationDotSlash,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMars,
    faMegaphone,
    faMegaphone,
    faMobile,
    faMoneyBill1Wave,
    faNote,
    faPaperclip,
    faPartyHorn,
    faPause,
    faPen,
    faPercent,
    faPhone,
    faPlay,
    faPlugCircleMinus,
    faPlugCircleMinus,
    faPlugCirclePlus,
    faPlugCirclePlus,
    faPlus,
    faPowerOff,
    faPuzzlePiece,
    faQuestion,
    farArrowDownFromLine,
    farArrowRightArrowLeft,
    farArrowRightFromArc,
    farArrowRightFromBracket,
    farArrowRightToArc,
    farArrowUpFromLine,
    farBell,
    farBook,
    farBookOpen,
    farBriefcase,
    farBriefcaseMedical,
    farCalculator,
    farCalculatorSimple,
    farCalendar,
    farCalendarClock,
    farCalendarDay,
    farCalendars,
    farCalendarWeek,
    farChartLine,
    farCircle,
    farCircleCheck,
    farCircleDashed,
    farCircleExclamation,
    farCircleInfo,
    farCircleXmark,
    farClipboardList,
    farClipboardListCheck,
    farCoins,
    faRepeat,
    farEquals,
    farFileInvoice,
    farFileInvoiceDollar,
    farFileLines,
    farFileMagnifyingGlass,
    farFiles,
    farFileZip,
    farFloppyDisk,
    farFolder,
    farFolders,
    farGear,
    farGlobe,
    farHandHoldingDollar,
    farHandWave,
    farHourglassHalf,
    farHouse,
    farIdCard,
    farInputNumeric,
    farInputText,
    farKey,
    farLaptopMobile,
    farList,
    farListCheck,
    farListDropdown,
    farLoader,
    farMessage,
    farMinusCircle,
    faRotate,
    faRotateLeft,
    farPenField,
    farPercentage,
    farPizzaSlice,
    farPlane,
    farPlusCircle,
    farRobot,
    farRocket,
    farSidebar,
    farSidebarFlip,
    farSliders,
    farSmileWink,
    farStar,
    farToggleOn,
    farTriangleExclamation,
    farUndo,
    farUser,
    farUserCheck,
    farUserClock,
    farUserGraduate,
    farUserGroup,
    farUserHelmetSafety,
    farUserMagnifyingGlass,
    farUserPlus,
    farUsers,
    farUserSlash,
    farUserTie,
    farUserVneckHair,
    farUserXmark,
    farWarning,
    faSackDollar,
    fasArchive,
    fasArchive,
    fasArrowDownFromLine,
    fasArrowRightArrowLeft,
    fasArrowRightFromBracket,
    fasArrowUpFromLine,
    fasBars,
    fasBell,
    fasBook,
    fasBookOpen,
    fasBriefcase,
    fasBuilding,
    fasBuildings,
    fasCalculator,
    faScaleBalanced,
    fasCalendar,
    fasCalendars,
    fasCalendarWeek,
    fasChartLine,
    fasCircleCheck,
    fasCircleDashed,
    fasCircleExclamation,
    fasCircleInfo,
    fasCircleXmark,
    fasClock,
    fasCoins,
    fasComment,
    faSearch,
    faSend,
    fasFileCheck,
    fasFileCirclePlus,
    fasFileLines,
    fasFiles,
    fasFiles,
    fasFileSignature,
    fasFolder,
    fasGear,
    faShieldCheck,
    faShieldQuartered,
    fasHouse,
    faSignature,
    fasInputNumeric,
    fasInputText,
    fasKey,
    fasLaptopMobile,
    faSliders,
    fasList,
    fasListCheck,
    fasListDropdown,
    fasLoader,
    fasMessage,
    faSms,
    fasPen,
    fasPenField,
    fasPizzaSlice,
    fasPlugCircleMinus,
    fasPlugCircleMinus,
    fasPlugCirclePlus,
    fasPlugCirclePlus,
    fasPuzzlePiece,
    faSquare1,
    faSquare2,
    fasRocket,
    fasScaleBalanced,
    fasShieldCheck,
    fasShieldQuartered,
    fasSliders,
    faStethoscope,
    fasThumbtack,
    fasToggleOn,
    fasTriangleExclamation,
    faSuitcase,
    fasUser,
    fasUserGroup,
    fasUserHelmetSafety,
    fasUserPlus,
    fasUsers,
    fasVideo,
    faTablet,
    faThumbtack,
    faTrash,
    faTriangleExclamation,
    faUpload,
    faUserCrown,
    faUserPen,
    faUsers,
    faUserXmark,
    faUtensils,
    faVenus,
    faVideo,
    faWandMagicSparkles,
    faXmark,
    faXmarkToSlot,
);
