import {ApiBase} from '@jetCommon/api/base.js';

export default class ContractorInvoiceApiCommon extends ApiBase {
    static resourceName = 'contractor_invoices';

    getExportSepaUrl(params) {
        return this.getApiUrlWithParams('export_sepa/', params);
    }

    getContractorsMissingIban(params = {}) {
        return this.apiGet('contractors_missing_iban/', {params});
    }

    markAsPaid(data) {
        return this.apiPatch('mark_as_paid/', data);
    }

    markAsToPay(data) {
        return this.apiPatch('mark_as_to_pay/', data);
    }

    rejectInvoice(id, data) {
        return this.apiPatch(`${id}/reject_invoice/`, data);
    }
}
